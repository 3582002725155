<template>
  <div id="target-categories">
    <v-row class="justify-start">
      <v-col cols="12" sm="12">
        <div class="display-1">
          Target Categories
          <v-btn
            @click="next()"
            class="float-right btns color-white"
            color="#109CF1"
            elevation="0"
            >Next</v-btn
          >
          <v-btn
            @click="$router.go(-1)"
            class="float-right btns color-white mr-4"
            color="#109CF1"
            elevation="0"
            >Back</v-btn
          >
        </div>
      </v-col>
    </v-row>
    <v-row v-if="loading.cats">
      <v-col cols="12" sm="12" class="h-screen">
        <v-progress-circular
          id="sharethis-loading"
          :size="50"
          color="#94D227"
          indeterminate
        ></v-progress-circular>
      </v-col>
    </v-row>
    <v-row class="my-12 justify-between" v-else>
      <v-col cols="12" sm="6" v-for="(item, index) in categories" :key="index">
        <v-card
          elevation="0"
          outlined
          class="mx-auto cursor-pointer cat-card"
          height="300"
          @click="next(item.category)"
        >
          <div class="card-body category pa-2">
            <div class="cat-img">
              <img class="rounded rounded-lg w-auto" :src="item.image" alt="" />
            </div>
          </div>
          <div class="card-footer cat-title font-weight-bold capitalize pa-2">
            {{ item.category }}
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: {
        cats: true,
      },
      approchName: this.$store.getters.getApproch,
      tierName: this.$store.getters.getSegBtn,
      categories: [],
      ModalData: {
        data: [],
        labels: [],
      },
      forceRender: 1,
    };
  },
  mounted() {
    this.getCategories();
  },
  methods: {
    async getCategories() {
      await this.$http
        .post(
          "/sharethis_activated_categories?clustering_technique=" +
            this.approchName +
            "&tier=" +
            this.tierName
        )
        .then((res) => {
          console.log("sharethis_activated_categories==>", res.data);
             this.getCategoriesImages(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getCategoriesImages(cats) {
      await this.$http
        .post("/sharethis_activated_categories_images", cats)
        .then((res) => {
          console.log("sharethis_activated_categories_images==>", res.data);
          this.categories = res.data;
          this.loading = {
            cats: false,
          };
        })
        .catch((err) => {
          console.log(err);
        });
    },
    next(cat) {
      this.$store.commit("setStepper", { type: "sharethis", val: 2 });
      this.$store.commit("setSelectedCat", cat);
      this.$router.push({ path: "/customers-events" });
    },
  },
};
</script>

<style lang="scss" scoped>
#target-categories::v-deep {
  .cat-img img {
    object-fit: cover !important;
    width: 100% !important;
    object-position: center !important;
  }
  .card-body * {
    height: 100% !important;
  }
  .card-body {
    height: 80% !important;
  }
  .card-footer {
    height: 20% !important;
  }
  .cat-card:hover * {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  .cat-card * {
    -webkit-transform: scale(0.995);
    transform: scale(0.995);
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
  }
  .cat-card:hover {
    border: 2px solid #94d227 !important;
    box-shadow: 0px 0px 15px #94d227 !important;
  }
}
</style>